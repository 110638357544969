import type { FC } from 'react';
import { useState } from 'react';
import LinkExternal01Icon from '@untitled-ui/icons-react/build/esm/LinkExternal01';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface Feature {
  id: string;
  title: string;
  description: string;
  imageLight: string;
}

const features: Feature[] = [
  {
    id: 'step-1',
    title: 'Step 1: Upload your Resume',
    description: 'Initiate your personalized interview simulation by uploading your resume. Our AI analyzes your experience, skills, and education to formulate questions that are customized for your professional profile.',
    imageLight: '/assets/features/upload-resume.png'
  },
  {
    id: 'step-2',
    title: 'Step 2: Copy and paste your job description',
    description: "Tailor your interview prep to your dream job by providing the job description. Our AI will dissect the key qualifications and responsibilities to generate relevant questions, making your practice sessions as realistic as possible.",
    imageLight: '/assets/features/job-description.png'
  },
  {
    id: 'step-3',
    title: 'Step 3: Start Interview!',
    description: "With your resume details and job description analyzed, it's time to dive into your interactive mock interview. Face a variety of questions and scenarios that the AI has crafted for you, honing your responses in real-time.",
    imageLight: '/assets/features/start-interview.jpeg'
    
  },
  {
    id: 'step-4',
    title: 'Step 4: Receive Personalized Feedback and Insights',
    description: "Once your interview is complete, our AI provides concise feedback to pinpoint your strengths and suggest specific areas for improvement. You'll learn how to better articulate your thoughts, tailor your responses to the job requirements, and present yourself with confidence.",
    imageLight: '/assets/features/interview-feedback.png'
  },
  {
    id: 'step-5',
    title: 'Step 5: Review Your Progress',
    description: "Access and revisit your past interviews at any time. Our platform archives each session, allowing you to listen to your previous answers and review the feedback you received. This reflective practice is key to recognizing your improvement over time and solidifying the skills you've honed.",
    imageLight: '/assets/features/interview-historical.png'
  }
];

export const HomeFeatures: FC = () => {
  const theme = useTheme();
  const [activeFeature, setActiveFeature] = useState<number>(0);
  const feature = features[activeFeature];
  const image =  feature.imageLight;

  return (
    <Box
      sx={{
        backgroundColor: 'neutral.800',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        color: 'common.white',
        py: '120px'
      }}
    >
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          sx={{ mb: 8 }}
        >
          <Typography
            align="center"
            color="inherit"
            variant="h3"
          >
            Simulate your Upcoming Job Interview.
          </Typography>
        </Stack>
        <Grid
          alignItems="center"
          container
          spacing={3}
        >
          <Grid
            xs={12}
            md={6}
          >
            <Stack spacing={1}>
              {features.map((feature, index) => {
                const isActive = activeFeature === index;

                return (
                  <Box
                    key={feature.id}
                    onClick={() => setActiveFeature(index)}
                    sx={{
                      borderRadius: 2.5,
                      color: 'neutral.400',
                      cursor: 'pointer',
                      p: 3,
                      transition: (theme) => theme.transitions.create(
                        ['background-color, box-shadow', 'color'],
                        {
                          easing: theme.transitions.easing.easeOut,
                          duration: theme.transitions.duration.enteringScreen
                        }
                      ),
                      ...(isActive && {
                        backgroundColor: 'primary.alpha12',
                        boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`,
                        color: 'common.white'
                      }),
                      '&:hover': {
                        ...(!isActive && {
                          backgroundColor: 'primary.alpha4',
                          boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`,
                          color: 'common.white'
                        })
                      }
                    }}
                  >
                    <Typography
                      color="inherit"
                      sx={{ mb: 1 }}
                      variant="h6"
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      color="inherit"
                      variant="body2"
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
          >
            <Box
              sx={{
                '& img': {
                  width: '100%',
                  borderRadius: '15px',
                }
              }}
            >
              <img src={image} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
