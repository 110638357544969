import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { codeStyle } from 'src/utils/code-style';

interface Sample {
  lang: 'jsx' | 'tsx';
  label: string;
  icon: string;
  code: string;
}

const samples: Sample[] = [
  {
    lang: 'jsx',
    label: '.jsx',
    icon: '/assets/logos/logo-javascript.svg',
    code: `"import { useState } from 'react';\\nimport { usePageView } from 'src/hooks/use-page-view';\\nimport { useUser } from 'src/hooks/use-user';\\n\\nconst Page = () => {\\n  const user = useUser();\\n  const [currentTab, setCurrentTab] = useState('general');\\n\\n  usePageView();\\n\\n  return (\\n    <Box\\n      component=\\"main\\"\\n      sx={{ flexGrow: 1, py: 8 }}\\n    >\\n      <Container maxWidth=\\"xl\\">\\n        <Stack\\n          spacing={3}\\n          sx={{ mb: 3 }}\\n        >\\n          <Typography variant=\\"h4\\">\\n            Account\\n          </Typography>\\n          <div>\\n            <Tabs\\n              indicatorColor=\\"primary\\"\\n              onChange={() => {}}\\n              scrollButtons=\\"auto\\"\\n              textColor=\\"primary\\"\\n              value={currentTab}\\n              variant=\\"scrollable\\"\\n            >\\n              {[].map((tab) => (\\n                <Tab\\n                  key={tab.value}\\n                  label={tab.label}\\n                  value={tab.value}\\n                />\\n              ))}\\n            </Tabs>\\n            <Divider />\\n          </div>\\n        </Stack>\\n      </Container>\\n    </Box>\\n  );\\n};\\n"`
  },
  {
    lang: 'tsx',
    label: '.tsx',
    icon: '/assets/logos/logo-typescript.svg',
    code: `"import { useState } from 'react';\\nimport type { NextPage } from 'next';\\nimport { usePageView } from 'src/hooks/use-page-view';\\nimport { useUser } from 'src/hooks/use-user';\\n\\nconst Page: NextPage = () => {\\n  const user = useUser();\\n  const [currentTab, setCurrentTab] = useState<string>('general');\\n\\n  usePageView();\\n\\n  return (\\n    <Box\\n      component=\\"main\\"\\n      sx={{ flexGrow: 1, py: 8 }}\\n    >\\n      <Container maxWidth=\\"xl\\">\\n        <Stack\\n          spacing={3}\\n          sx={{ mb: 3 }}\\n        >\\n          <Typography variant=\\"h4\\">\\n            Account\\n          </Typography>\\n          <div>\\n            <Tabs\\n              indicatorColor=\\"primary\\"\\n              onChange={() => {}}\\n              scrollButtons=\\"auto\\"\\n              textColor=\\"primary\\"\\n              value={currentTab}\\n              variant=\\"scrollable\\"\\n            >\\n              {[].map((tab) => (\\n                <Tab\\n                  key={tab.value}\\n                  label={tab.label}\\n                  value={tab.value}\\n                />\\n              ))}\\n            </Tabs>\\n            <Divider />\\n          </div>\\n        </Stack>\\n      </Container>\\n    </Box>\\n  );\\n};\\n"`
  }
];

export const HomeCodeSamples: FC = () => {
  const [lang, setLang] = useState<string>(samples[0].lang);

  const handleLangChange = useCallback((event: ChangeEvent<{}>, value: string): void => {
    setLang(value);
  }, []);

  const code = useMemo(() => {
    return samples.find((sample) => sample.lang === lang)?.code.trim() || '';
  }, [lang]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.neutral[800], .95),
          borderBottomColor: 'neutral.700',
          borderRadius: '15px',
          boxShadow: 24,
          flex: '0 0 auto',
          overflow: 'hidden',
          px: 2
        }}
      >
      </Stack>
      <img
        src={'/assets/interview.png'}
        style={{
          maxWidth: '80%', // Adjusts the size to be smaller, keeping aspect ratio
          height: 'auto', // Ensures the height adjusts according to the width to maintain aspect ratio
          borderRadius: '15px' // Sets the border radius
        }}
      />

    </Box>
  );
};
