import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useTheme, useMediaQuery } from '@mui/material';
import './home-hero.css'
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import { Helmet } from 'react-helmet-async';
import Vapi from '@vapi-ai/web';

import { HomeCodeSamples } from './home-code-samples';

export const HomeHero: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const vapi_public_key = process.env.REACT_APP_VAPI_PUBLIC_KEY_GPT35 || 'no_key'
  const vapi = new Vapi(vapi_public_key);
  const [speaking, setSpeaking] = useState<boolean>(false);
  const [startInterview, setStartInterview] = useState<boolean>(false);
  const jobPositions = ["Data Analyst", "Project Manager", 'Financial Associate'];
  const [selectedJob, setSelectedJob] = useState<string>(
    jobPositions[Math.floor(Math.random() * jobPositions.length)]
  );
  const [systemPrompt, setSystemPrompt] = useState<string>('');
  const randomIndex = Math.floor(Math.random() * jobPositions.length);
  const randomJob = jobPositions[randomIndex];




useEffect(() => {
    if (startInterview) {
        vapi.start({
            firstMessage: '  Hi! Thank you for taking the time to speak with me today!  How are you?',
            model: {
                "model": "gpt-3.5-turbo",
                "provider": "openai",
                'systemPrompt': systemPrompt
            },
            recordingEnabled: true,
            interruptionsEnabled: false
        });

        // Event listener for speech start
        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setSpeaking(true);  // Set speaking to true when speech starts
        });

        // Event listener for speech end
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setSpeaking(false);  // Set speaking to false when speech ends
        });

        vapi.on('call-end', () => {
            console.log('Call has ended')
            setSpeaking(false);
            setStartInterview(false)
        });

        return () => {
            vapi.stop();
        };
    }
}, [startInterview]);
  

  return (
    <>
    <Helmet>
      <meta
          name="description"
          content="Unlock your full potential with our AI-enhanced mock interviews: The pathway to your career development and professional excellence."
        />
    </Helmet>
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        pt: '120px'
      }}
    >
      <Container maxWidth="lg">
        <Box maxWidth="sm">
          <Typography
            variant="h1"
            sx={{ mb: 2 }}
          >
            Ace your Upcoming Job<span className="tool_gradient-text"> Interview</span> 
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontSize: 20,
              fontWeight: 500
            }}
          >
            AI-powered mock interviews crafted for you to boost your confidence and success in the job market
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            spacing={1}
            sx={{ my: 3 }}
          >
            <Rating
              readOnly
              value={4.7}
              precision={0.1}
              max={5}
            />
            <Typography
              color="text.primary"
              variant="caption"
              sx={{ fontWeight: 700 }}
            >
              4.7/5
            </Typography>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              based on (300+ reviews)
            </Typography>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
          </Stack>
        </Box>
        <Box
          sx={{
            pt: '120px',
            position: 'relative'
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              width: '90%',
              fontSize: 0,
              mt: -2, // hack to cut the bottom box shadow
              mx: -2,
              pt: 2,
              px: 2,
              '& img': {
                borderTopLeftRadius: (theme) => theme.shape.borderRadius * 2.5,
                borderTopRightRadius: (theme) => theme.shape.borderRadius * 2.5,
                boxShadow: 16,
                width: '100%'
              }
            }}
          >
            <img
              src={ '/assets/platform.png'}
            />
          </Box>
          {isDesktop && (
          <Box
            sx={{
              maxHeight: '100%',
              maxWidth: '100%',
              overflow: 'hidden',
              position: 'absolute',
              right: -260,
              top: 40,
              '& > div': {
                height: 460,
                width: 660
              }
            }}
          >
            <HomeCodeSamples />
          </Box>
          )}
        </Box>
      </Container>
    </Box>
    </>
  );
};