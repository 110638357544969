import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie'; 
import { AuthProvider } from 'src/contexts/auth/jwt-context';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api'; // http://localhost:3001/api';  // Assuming your Node backend runs on port 5000


export const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

// client.interceptors.request.use((config) => {
//     const token = sessionStorage.getItem('accessToken');
//     console.log(token)
//     if (token) {
//         config.headers = {
//             ...config.headers,
//             'Authorization': `Bearer ${token}`,
//         };
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

export const fetchCsrfToken = async (): Promise<void> => {
    try {
        const response = await axios.get(`${API_BASE_URL}api/csrf/`, {
            withCredentials: true,
        });
        const csrfToken = response.data.csrfToken;
        client.defaults.headers.common['X-CSRFToken'] = csrfToken;
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        throw error;
    }
};


const googleLoginClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken') || '',
    },
    withCredentials: true,
});



export const refreshToken = (): Promise<AxiosResponse>  => {
    const refresh = localStorage.getItem('refreshToken'); // Assuming you store the refresh token here
    return client.post('/api/dj-rest-auth/token/refresh/', { refresh });
};


//***************** Login/Signup Functions ************************ */
// API call to backend to login with a Google token ID
export const googleLogin = async (googleTokenId: string, csrfToken: string) => {
    try {
        await fetchCsrfToken()

        const response = await googleLoginClient.post('/api/social/login/google/', {
            credential: googleTokenId,
            g_csrf_token: csrfToken,  // Include CSRF token if you're implementing CSRF protection
        });

        // Assuming the response will contain a custom token for your app and user data
        return {
            token: response.data.token, 
            refreshToken: response.data.refreshToken,
            user: {
                name: response.data.name, 
                email: response.data.email, 
                imageUrl: response.data.imageUrl, 
                credits: response.data.credits,
                planType: response.data.planType,
                planStartDate: response.data.planStartDate,
                planEndDate: response.data.planEndDate,
            }
        };
    } catch (error) {
        console.error('Error during the Google login process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};


// API call to backend to login with Facebook user data
export const facebookLogin = async (facebookData: any) => {
    try {
        await fetchCsrfToken();
        const response = await client.post('/api/social/login/facebook/', {
            userId: facebookData.userID,
            accessToken: facebookData.accessToken,
            // Include any other data required by your backend
        });

        // Assuming the response will contain a custom token for your app and user data
        return {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: {
                name: response.data.name, 
                email: response.data.email, 
                imageUrl: response.data.imageUrl, 
                credits: response.data.credits,
                planType: response.data.planType,
                planStartDate: response.data.planStartDate,
                planEndDate: response.data.planEndDate,
            }
        };
    } catch (error) {
        console.error('Error during the Facebook login process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

// API call to backend to login with LinkedIn user data
export const linkedInLogin = async (facebookData: any) => {
    try {
        await fetchCsrfToken();
        const response = await client.post('/api/social/login/linkedin/', {
            userId: facebookData.userID,
            accessToken: facebookData.accessToken,
            // Include any other data required by your backend
        });

        // Assuming the response will contain a custom token for your app and user data
        return {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: {
                name: response.data.name, 
                email: response.data.email, 
                imageUrl: response.data.imageUrl, 
                credits: response.data.credits,
                planType: response.data.planType,
                planStartDate: response.data.planStartDate,
                planEndDate: response.data.planEndDate,
            }
        };
    } catch (error) {
        console.error('Error during the Facebook login process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

export const emailPasswordLogin = async (email: string, password: string, csrfToken: string) => {
    try {
        await fetchCsrfToken();

        const response = await client.post('/api/login/', {
            email,
            password,
            csrfmiddlewaretoken: csrfToken,
        });
        // Assuming the response contains the token and user data
        return {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: {
                id: response.data.name,
                name: response.data.name,
                email: response.data.email,
                imageUrl: response.data.imageUrl,
                credits: response.data.credits,
                planType: response.data.planType,
                planStartDate: response.data.planStartDate,
                planEndDate: response.data.planEndDate,
            }
        };
    } catch (error) {
        console.error('Error during the email and password login process:', error);
        throw error;
    }
};

export const emailPasswordSignUp = async (email: string, password: string, name: string, csrfToken: string) => {
    try {
        await fetchCsrfToken();
        const response = await client.post('/api/signup/', {
            email,
            password,
            name,
            csrfmiddlewaretoken: csrfToken,
        });

        // Assuming the response contains a token and user data similar to the login response
        return {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: {
                id: response.data.name,
                name: response.data.name,
                email: response.data.email,
                imageUrl: response.data.imageUrl,
                credits: response.data.credits,
                planType: response.data.planType,
                planStartDate: response.data.planStartDate,
                planEndDate: response.data.planEndDate,
            }
        };
    } catch (error) {
        console.error('Error during the sign-up process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};


//***************************************************************** */


export const analyzeCV = async (firstName: string, position: string, company: string, jobDescription: string, cvFile: File | null) => {
    try {
        await fetchCsrfToken();
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('position', position);
        formData.append('company', company);
        formData.append('jobDescription', jobDescription);
        if (cvFile) {
            formData.append('cvFile', cvFile);
        }

        const response = await client.post('/api/analyze-cv/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',  // Important for sending files
            },
        });

        return response.data;  // Handle the response data as needed
    } catch (error) {
        console.error('Error during the CV analysis process:', error);
        throw error;  // Rethrow the error to be handled by the caller
    }
};




export const updateCredits = async (creditChange: number) => {
    try {
        await fetchCsrfToken();
        const response = await client.post('/api/update_credits/', {
            credits: creditChange,
        });

        return {
            message: response.data.message, // Message from backend confirming the update
            newCredits: response.data.new_credits, // The updated credits amount
        };
    } catch (error) {
        console.error('Error during the credit update process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};


export const getStripeCheckoutLink = async (planType: string) => {
    try {
        const response = await client.post('/api/create-stripe-session/', { plan: planType });
        return {
            url: response.data.url,
            sessionId: response.data.sessionId
        };

    } catch (error) {
        console.log(error)
        console.error('Error during Stripe checkout process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

export interface StripeSubscriptionResponse {
    planType: string;
    planStartDate: string;
    planEndDate: string;
  }


export const getStripeSubscription = async (token: any): Promise<StripeSubscriptionResponse> => {
    try {
        const response = await client.get('/api/get-stripe-subscription/', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });

        // Map the response data to your TypeScript interface
        return {
            planType: response.data.planType,
            planStartDate: response.data.planStartDate,
            planEndDate: response.data.planEndDate,
        };
    } catch (error) {
        console.error('Error during fetching Stripe subscription:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

export const registerForBeta = async (email: string, name: string) => {
    try {
        // Make sure CSRF token is fetched and set
        await fetchCsrfToken();

        // POST request to your backend's beta signup endpoint
        const response = await client.post('/api/beta/signup/', {
            email,
            name,
        });

        // Assuming the response will contain some kind of success message or user ID
        return response.data; // Handle or return the response data as needed
    } catch (error) {
        console.error('Error during the beta registration process:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};
