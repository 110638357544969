import React, { useState, useEffect } from 'react';

const Page: React.FC = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  useEffect(() => {
    fetch('privacy.html')
      .then(response => response.text())
      .then(data => {
        setPrivacyPolicy(data);
      });
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
    </div>
  );
};

export default Page;
