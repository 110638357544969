export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    new_interviews: 'nav.new_interviews',
    my_interviews: 'nav.my_interviews',
    settings: 'nav.settings',
    blog: 'nav.blog',
      }
};
