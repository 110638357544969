import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';

import { Layout as AuthClassicLayout } from 'src/layouts/auth/classic-layout';
import { Layout as AuthModernLayout } from 'src/layouts/auth/modern-layout';
import { Outlet } from 'react-router-dom';

const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/forgot-password/modern'));
const LoginModernPage = lazy(() => import('src/pages/auth-demo/login/modern'));
const RegisterModernPage = lazy(() => import('src/pages/auth-demo/register/modern'));
const ResetPasswordModernPage = lazy(() => import('src/pages/auth-demo/reset-password/modern'));
const VerifyCodeModernPage = lazy(() => import('src/pages/auth-demo/verify-code/modern'));

export const authDemoRoutes: RouteObject[] = [
  {
    path: 'auth',
    children: [
      // {
      //   element: (
      //     <AuthClassicLayout>
      //       <Suspense>
      //         <Outlet />
      //       </Suspense>
      //     </AuthClassicLayout>
      //   ),
      //   children: [
      //     {
      //       path: 'forgot-password/classic',
      //       element: <ForgotPasswordClassicPage />
      //     },
      //     {
      //       path: 'login/classic',
      //       element: <LoginClassicPage />
      //     },
      //     {
      //       path: 'register/classic',
      //       element: <RegisterClassicPage />
      //     },
      //     {
      //       path: 'reset-password/classic',
      //       element: <ResetPasswordClassicPage />
      //     },
      //     {
      //       path: 'verify-code/classic',
      //       element: <VerifyCodeClassicPage />
      //     }
      //   ]
      // },
      {
        element: (
          <AuthModernLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </AuthModernLayout>
        ),
        children: [
          {
            path: 'forgot-password',
            element: <ForgotPasswordModernPage />
          },
          {
            path: 'login',
            element: <LoginModernPage />
          },
          {
            path: 'register',
            element: <RegisterModernPage />
          },
          {
            path: 'reset-password',
            element: <ResetPasswordModernPage />
          },
          {
            path: 'verify-code',
            element: <VerifyCodeModernPage />
          }
        ]
      }
    ]
  }
];
