import type { FC, ReactNode } from 'react';
import { client } from 'src/api/django-call/api';
import { createContext, useCallback, useEffect, useReducer, useContext, useState } from 'react';
import PropTypes from 'prop-types';
// import { authApi } from 'src/api/auth';
import type { User } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import { emailPasswordLogin, emailPasswordSignUp, refreshToken } from 'src/api/django-call/api';


const STORAGE_KEY = 'accessToken';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_OUT = 'SIGN_OUT'
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: User;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<void>;
  signInSocial: (token: string, user: User) => Promise<void>;
  signUp: (email: string, name: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  setToken: (newToken: string | null, newUser?: User | null) => void;
  updateUserWithSubscription: (subscription: any) => void;
  token: string | null; 
  user: User | null;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signInSocial: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  setToken: () => {},
  updateUserWithSubscription: () => {},
  token: null,
  user: null,
});

interface AuthProviderProps {
  children: ReactNode;
}



export const AuthProvider: FC<AuthProviderProps> = (props) => {
  
  const { children } = props;
  const [token, setToken_] = useState<string | null>(localStorage.getItem("token"));
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState<User | null>(null);

  // Initialization useEffect
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (storedToken && storedUser) {
      console.log('TESTING')
      const user = JSON.parse(storedUser);
      setToken_(storedToken);
      setUser(user);
      dispatch({ type: ActionType.INITIALIZE, payload: { isAuthenticated: true, user: user } });
    } else {
      // Handle case where there is no token or user, perhaps redirect to login or set isAuthenticated to false
      dispatch({ type: ActionType.INITIALIZE, payload: { isAuthenticated: false, user: null } });
    }
  }, []);


  const signIn = useCallback(async (email: string, password: string): Promise<void> => {
    try {
      const csrfToken = '' 
      const { token, refreshToken, user } = await emailPasswordLogin(email, password, csrfToken);
      localStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem(STORAGE_KEY, token);
      localStorage.setItem('user', JSON.stringify(user));
      setToken(token, user)
      
      dispatch({ type: ActionType.SIGN_IN, payload: { user: user } });
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }, [dispatch]);


  const updateUserWithSubscription = (subscription: any) => {
    if (user) {
      const updatedUser: User = {
        ...user,
        planType: subscription.planType,
        planStartDate: subscription.planStartDate,
        planEndDate: subscription.planEndDate
      };
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  };

  const signInSocial = useCallback(async (token: string, user: User): Promise<void> => {
    try {
      sessionStorage.setItem(STORAGE_KEY, token);
      setToken(token, user)
      localStorage.setItem('user', JSON.stringify(user));
      dispatch({ type: ActionType.SIGN_IN, payload: { user: user } });
      console.log(user)
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }, [dispatch]);

  const handleTokenRefresh = async () => {
    try {
      const response = await refreshToken();
      const newToken = response.data.access;
      setToken(newToken); // Update the token and optionally the user data
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  const signUp = useCallback(async (email: string, name: string, password: string): Promise<void> => {
    try {
      const csrfToken = '' // Retrieve CSRF token if required
      const response = await emailPasswordSignUp(email, password, name, csrfToken);
      sessionStorage.setItem(STORAGE_KEY, response.token);
      setToken(response.token, user)
      localStorage.setItem('user', JSON.stringify(response.user));
      dispatch({ type: ActionType.SIGN_UP, payload: { user: response.user } });
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  }, [dispatch]);
  

  const signOut = useCallback(async (): Promise<void> => {
    sessionStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem('user');
    localStorage.removeItem('token'); 
    localStorage.removeItem('refreshToken'); 
    setToken(null, null)
    dispatch({ type: ActionType.SIGN_OUT });
    
  }, [dispatch]);

  const setToken = (newToken: string | null, newUser?: User | null) => {
    setToken_(newToken);
    if (newToken && newUser) {
      setUser(newUser);
      localStorage.setItem('user', JSON.stringify(newUser)); // Store user data in localStorage
    } else {
      setUser(null);
      localStorage.removeItem('user');
    }
  };

  useEffect(() => {
    if (token) {
      client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem('token', token);
      console.log('New token!')
      // Set a timer to refresh the token before it expires
      const expiresIn = 240000;
      const someBufferTime = 30000;
      const timeoutId = setTimeout(handleTokenRefresh, expiresIn - someBufferTime);

      // Clear the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    } else {
      delete client.defaults.headers.common["Authorization"];
      localStorage.removeItem('token');
    }
  }, [token]);
  

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signInSocial,
        signUp,
        signOut,
        setToken,
        updateUserWithSubscription,
        token,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthConsumer = AuthContext.Consumer;
