import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { Layout as MarketingLayout } from 'src/layouts/marketing';
import HomePage from 'src/pages';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import ContactPage from 'src/pages/contact';
import PricingPage from 'src/pages/pricing';
import SuccessPage from 'src/pages/success';
import PrivacyPage from 'src/pages/privacy';

import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { componentsRoutes } from './components';
import { dashboardRoutes } from './dashboard';

const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));

export const routes: RouteObject[] = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: 'pricing',
        element: <PricingPage />
      },
      // {
      //   path: 'success',
      //   element: <SuccessPage />
      // },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostListPage />
          },
          {
            path: ':postId',
            element: <BlogPostDetailPage />
          }
        ]
      },
 
      // ...componentsRoutes,
    ]
  },
  // ...authRoutes,
  ...authDemoRoutes,
  // ...dashboardRoutes,
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  },
  {
    path: 'privacy',
    element: <PrivacyPage />
  },
];
