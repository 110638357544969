export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  blog: {
    index: '/blog',
    postDetails: '/blog/:postId',
    postCreate: '/blog/create'
  },
  privacy:'/privacy',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register'
    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      // classic: '/auth-demo/forgot-password/classic',
      modern: '/auth/forgot-password'
    },
    login: {
      // classic: '/auth-demo/login/classic',
      modern: '/auth/login'
    },
    register: {
      // classic: '/auth-demo/register/classic',
      modern: '/auth/register'
    },
    resetPassword: {
      // classic: '/auth-demo/reset-password/classic',
      modern: '/auth/reset-password'
    },
    verifyCode: {
      // classic: '/auth-demo/verify-code/classic',
      modern: '/auth-/verify-code'
    }
  },
  dashboard: {
    index: '/dashboard',
    settings: '/dashboard/settings',
    new_interviews: '/dashboard/new_interview',
    my_interviews: {
      index: '/dashboard/my_interviews',
      interviewDetails: '/dashboard/my_interviews/:interviewId',
    },
    
  },
  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
