import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react'
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { PricingFaqs } from 'src/sections/pricing/pricing-faqs';
import { PricingPlan } from 'src/sections/pricing/pricing-plan';
import { PricingPlanIcon } from 'src/sections/pricing/pricing-plan-icon';
import { getStripeCheckoutLink } from 'src/api/django-call/api';
import { useNavigate, } from 'react-router-dom';
import { useAuth } from 'src/contexts/auth/jwt-context';


const Page = () => {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  usePageView();

  useEffect(() => {
    if (token && user) {
      if (['basic', 'pro', 'premium'].includes(user.planType || '')) {
        navigate('/dashboard');
      }
    }
  }, [token, user, navigate]);


  return (
    <>
      <Seo title="Pricing" />
      <Box
        component="main"
        sx={{ flexGrow: 1 }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'neutral.800'
              : 'neutral.50',
            pb: '120px',
            pt: '184px'
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                mb: 4
              }}
            >
              <Typography variant="h3">
                Start today. Boost your interview skills!
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ my: 2 }}
                variant="body1"
              >
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
              </Stack>
            </Box>
            <Grid
              container
              spacing={4}
            >
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta="Try for Free!"
                  currency="$"
                  description=""
                  features={[
                    'Unilimited interview practice',
                    'Powered by GPT 4-Turbo',
                    'Upload job description and resume for personalized questions',
                    'AI analysis with feedback and response metrics',
                    'Access to all your interviews'
                  ]}
                  icon={<PricingPlanIcon name="startup" />}
                  name="1-Month Package"
                  price="29"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta="Try for Free!"
                  currency="$"
                  description=""
                  features={[
                    'Unilimited interview practice',
                    'Powered by GPT 4-Turbo',
                    'Upload job description and resume for personalized questions',
                    'AI analysis with feedback and response metrics',
                    'Access to all your interviews'
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name="3-months Package"
                  popular
                  price="59"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta="Try for Free!"
                  currency="$"
                  description=""
                  features={[
                    'Unilimited interview practice',
                    'Powered by GPT 4-Turbo',
                    'Upload job description and resume for personalized questions',
                    'AI analysis with feedback and response metrics',
                    'Access to all your interviews'
                  ]}
                  icon={<PricingPlanIcon name="business" />}
                  name="9-month package"
                  price="69"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta="Sign Up"
                  currency=""
                  description=""
                  features={[
                    'Unilimited interview practice',
                    'Powered by GPT 4-Turbo',
                    'Upload job description and resume for personalized questions',
                    'AI analysis with feedback and response metrics',
                    'Access to all your interviews'
                  ]}
                  enterprise
                  icon={<PricingPlanIcon name="business" />}
                  name="Email valdir@speaksmart.io for pricing details"
                  price="Enterprise/Staffing Agencies"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto'
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />
        {/* <PricingFaqs /> */}
      </Box>
    </>
  );
};

export default Page;
